@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PolySansNeutral;
  src: url(./assets/fonts/PolySans-Neutral.otf);
}

@font-face {
  font-family: PolySansMedian;
  src: url(./assets/fonts/GPPolySans-Median.otf);
}

@font-face {
  font-family: BasierCircle;
  src: url(./assets/fonts/BasierCircle-Regular.otf);
}

@font-face {
  font-family: BasierCircleMedium;
  src: url(./assets/fonts/BasierCircle-Medium.otf);
}

@font-face {
  font-display: swap;
  font-family: 'PolySans-Median';
  font-style: normal;
  font-weight: 600;
  src:
    url('./assets/fonts/PolySans-Median.woff2') format('woff2'),
    url('./assets/fonts/PolySans-Median.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'PolySans-Slim';
  font-style: normal;
  font-weight: 300;
  src:
    url('./assets/fonts/PolySans-Slim.woff2') format('woff2'),
    url('./assets/fonts/PolySans-Slim.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'BasierCircle-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/BasierCircle-Regular.otf') format('otf');
}

@keyframes bounceTwiceWithDelay {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(5px);
  }
}

.animate-bounce-twice-with-delay {
  animation: bounceTwiceWithDelay 2s infinite;
  animation-delay: 2s;
}

@layer utilities {
  .draggable-none {
    -webkit-user-drag: none;
    user-drag: none; /* For non-WebKit browsers */
  }
}

/*TODO remove these once customCss classes replaced by tailwindcss*/
:root {
  /* Gray Color */
  --color-gray-0: #ffffff;
  --color-gray-100: #f4f4f6;
  --color-gray-200: #edf0f3;
  --color-gray-300: #e3e8ed;
  --color-gray-400: #dae0e7;
  --color-gray-500: #c2ccd6;
  --color-gray-600: #a7b3be;
  --color-gray-700: #8a9aa8;
  --color-gray-800: #52616f;
  --color-gray-900: #3d4752;
  --color-gray-1000: #272e35;
  --color-gray-1100: #111417;

  /* Primary Colors */
  --color-primary-100: #e1eeff;
  --color-primary-200: #c7defe;
  --color-primary-300: #7ab3ff;
  --color-primary-400: #4084ff;
  --color-primary-450: #373df8;
  --color-primary-500: #0000ff;
  --color-primary-600: #001699;

  /* secondary Colors */
  --color-secondary-100: #d3ffb2;
  --color-secondary-200: #c3ff73;
  --color-secondary-300: #9cf820;
  --color-secondary-400: #71e618;
  --color-secondary-500: #56c900;
  --color-secondary-600: #2d8c00;

  /* tertiary colors */
  --color-tertiary-100: #cdfffb;
  --color-tertiary-200: #bffbff;
  --color-tertiary-300: #a3f8ff;
  --color-tertiary-400: #6af5ff;
  --color-tertiary-600: #00c8e0;
  --color-tertiary-500: #00edff;

  /* error colors */
  --color-error-100: #ffe1df;
  --color-error-200: #e61000;

  /* warnings */
  --color-warning-100: #fffdca;
  --color-warning-200: #ffe388;
  --color-warning-300: #ffc200;
  --color-warning-400: #f0b600;
  --color-warning-500: #ce9d00;

  /* confirmation colors */
  --color-confirmation-100: #d3ffb2;
  --color-confirmation-200: #56c900;
  /* info colors */
  --color-information-200: #4084ff;
  --color-information-100: #e1eeff;

  --core-spacing-gap-4: 4px;
  --core-spacing-gap-6: 6px;
  --core-spacing-gap-8: 8px;
  --core-spacing-gap-10: 10px;
  --core-spacing-gap-12: 12px;
  --core-spacing-gap-14: 14px;
  --core-spacing-gap-16: 16px;
  --core-spacing-gap-18: 18px;
  --core-spacing-gap-24: 24px;
  --core-spacing-gap-28: 28px;
  --core-spacing-gap-32: 32px;

  --core-spacing-h-padding-8: 8px;
  --core-spacing-h-padding-12: 12px;
  --core-spacing-h-padding-14: 14px;
  --core-spacing-h-padding-16: 16px;
  --core-spacing-h-padding-18: 18px;
  --core-spacing-h-padding-20: 20px;
  --core-spacing-h-padding-24: 24px;
  --core-spacing-h-padding-32: 32px;
  --core-spacing-v-padding-4: 4px;
  --core-spacing-v-padding-6: 6px;
  --core-spacing-v-padding-8: 8px;
  --core-spacing-v-padding-10: 10px;
  --core-spacing-v-padding-12: 12px;
  --core-spacing-v-padding-14: 14px;
  --core-spacing-v-padding-16: 16px;
  --core-spacing-v-padding-18: 18px;
  --core-spacing-v-padding-20: 20px;
  --core-spacing-v-padding-24: 24px;
  --core-spacing-v-padding-32: 32px;

  --core-border-radius-0: 0px;
  --core-border-radius-4: 4px;
  --core-border-radius-6: 6px;
  --core-border-radius-8: 8px;
  --core-border-radius-14: 14px;
  --core-border-radius-16: 16px;
  --core-border-radius-pill: 100px;

  --core-border-width-0: 0px;
  --core-border-width-1: 1px;
  --core-border-width-2: 2px;
  --core-border-width-3: 3px;
  --core-border-width-4: 4px;

  --core-opacity-50: 50%;
  --core-opacity-75: 75%;
  --core-opacity-80: 80%;
  --core-opacity-90: 90%;
  --core-opacity-100: 100%;

  --comp-nav-btn-spacing-back-gap: 6px;
  --comp-nav-btn-spacing-back-l-padding: 16px;
  --comp-nav-btn-spacing-back-r-padding: 18px;
  --comp-nav-btn-spacing-back-v-padding: 12px;
  --comp-nav-btn-border-width: 1px;
  --comp-nav-btn-border-radius: 8px;
}
body {
  margin: 0px;
}
