@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .survey-question-container {
    @apply box-border flex flex-col justify-center max-w-[720px] mx-20 my-0 px-0 py-12 grow shrink-0 basis-0;
  }
  .survey-question-title {
    @apply font-sans text-[28px] font-[400] leading-[120%] tracking-[0.24px] text-grey-1000 mb-4 p-0;
  }
  .survey-question-description {
    @apply font-basier text-[18px] font-[400] leading-[150%] tracking-[-0.18px] text-black opacity-[0.85] mb-8 p-0 max-w-[550px];
  }
  .writing-text {
    @apply font-basier text-[16px] font-[400] leading-[150%] tracking-[-0.18px];
  }
  .tag-pills {
    @apply p-4 flex box-border justify-center items-center h-auto px-4 py-3 border-2 border-solid border-grey-400 rounded-lg cursor-pointer;
  }
  .branch-page-button {
    @apply bg-primary-500 text-white gap-2 writing-text h-[57px] w-auto px-6 rounded-[100px] flex items-center justify-center border-[1px] border-solid tracking-[0.42px];
  }
}
